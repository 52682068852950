import React from 'react';
import { Section, Container } from '@components/global';
import FaqItem from '@common/FaqItem';
import Loveletter from '@images/logos/letter.svg';
const FAQS = [
  {
    title: 'Details',
    content: () => (
      <>
        Op 5 juni trouwen wij en start om 20:00 onze feestavond, en duurt tot
        00:00. Wij kijken uit naar jullie aanwezigheid hierbij! Op ons
        trouwfeest zijn kinderen niet welkom. <br />
        <br />
        Hippie Fish <br />
        Boulevard Paulus Loot 3 <br />
        2042 AD Zandvoort <br />
        <a
          href="https://goo.gl/maps/4DrKt3BCCSFbuygW6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zie route
        </a>
      </>
    ),
  },
  {
    title: 'Parkeren',
    content: () => (
      <>
        Er is <b>geen</b> parkeergelegenheid op het terrein van Hippie Fish
        zelf.
        <br />
        <br />
        Parkeren kun je aan de boulevard en indien daar geen plek is raden we je
        aan de auto te parkeren op:
        <br />
        Parkeerterrein: De Zuid <br />
        Ingenieur G. Friedhoffplein 2042 BP Zandvoort
        <br />
        <br />
        Hiervandaan is het nog 5 min. lopen naar Hippie Fish.
        <br />
        <br />
        <a
          href="https://goo.gl/maps/WdUA4zZzT95PznNW9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zie route naar parkeerterrein
        </a>
      </>
    ),
  },
  {
    title: 'Dresscode',
    content: () => (
      <>
        Wij zouden het erg op prijs stellen als iedereen in pastel tinten en
        zachte kleuren gekleed zou gaan. Denk aan tinten als linnen, mintgroen,
        lichtblauw, roze en <b>geen paars of rood.</b>
        <br />
        <br />
        En voor de dames houd er rekening mee dat alleen het beach house en de
        aanloop voorzien zijn van een harde ondervloer, hakken dragen op eigen
        risico.
      </>
    ),
  },
  {
    title: 'Kadotips',
    content: () => (
      <>
        <img src={Loveletter} alt="" width="50px" height="50px" />
      </>
    ),
  },
  {
    title: 'Vragen?',
    content: () => (
      <>
        Onze ceremoniemeester Yasmine beantwoordt ze graag! Mail naar:
        weddingroosnico@gmail.com OF yasmine.ragheb92@gmail.com
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h3 style={{ marginBottom: 40 }}>Informatie</h3>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
