import React from 'react';
import styled, { keyframes } from 'styled-components';
// import Img from 'gatsby-image';
import { Container } from '@components/global';
import { useSpring, animated } from 'react-spring';

import { ReactComponent as flowerLeft } from '@images/art/flowerLeft.svg';
import { ReactComponent as flowerRight } from '@images/art/flowerRight.svg';

const Header = () => {
  const fade = useSpring({
    from: {
      opacity: 0,
      delay: '30000',
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <HeaderWrapper>
      <StyledLogoL />
      <StyledLogoR />
      <Container>
        <Grid>
          <AnimatedText style={fade}>
            <h1>
              Wedding <br /> Roos & Nico
            </h1>
            <hr />
            <Date>5 juni 2020</Date>
            <hr />
          </AnimatedText>
        </Grid>
      </Container>
    </HeaderWrapper>
  );
};

const fadeSvg = keyframes`
    0%   { 
      transform: scale(1);
      opacity: 0
    }
    50%  { 
      transform: scale(1.4); 
    }
    100% {
      transform: scale(1.4); 
      opacity: 1;
      }
  `;

const StyledLogoR = styled(flowerLeft)`
  position: absolute;
  height: auto;
  animation: ${fadeSvg} 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  width: 16em;
  left: 0;
  top: 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 10em;
  }
`;
const StyledLogoL = styled(flowerRight)`
  position: absolute;
  animation: ${fadeSvg} 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  height: auto;
  width: 16em;
  right: 0;
  top: 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 10em;
  }
`;

const HeaderWrapper = styled(animated.header)`
  background-color: ${props => props.theme.color.beach};
  padding-top: 96px;
  padding-bottom: 20px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
    padding-bottom: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 10px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

const AnimatedText = styled(animated.div)`
  justify-self: center;
  text-align: center;
  order: 2;

  h1 {
    padding-bottom: 0.5em;
  }
`;

const Date = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default Header;
