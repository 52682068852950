import React from 'react';
import styled from 'styled-components';
// import Img from 'gatsby-image';
import { ReactComponent as spotify } from '@images/art/spotify.svg';
import ExternalLink from '@common/ExternalLink';
import { useSpring, animated } from 'react-spring';
import { Section, Container } from '@components/global';

const About = () => {
  const fade = useSpring({
    from: {
      opacity: 0,
      // delay: 60000,
    },
    to: {
      opacity: 1,
    },
  });

  return (
    <Section id="about">
      <Container>
        <Grid>
          <Form
            style={fade}
            action="https://usebasin.com/f/6700144c9adc"
            method="POST"
          >
            <h3>Geef hieronder je aanwezigheid door</h3>
            <label htmlFor="name">Naam</label>
            <input type="name" id="name" name="name" required />
            <label htmlFor="email-address">Email</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="aanwezig">Aanwezig</label>
            <div className="aanwezig-input">
              <input type="radio" id="aanwezig1" name="aanwezig" value="ja" />
              <label htmlFor="aanwezig1">ja</label>
              <input type="radio" id="aanwezig2" name="aanwezig" value="nee" />
              <label htmlFor="aanwezig2">nee</label>
            </div>
            <label htmlFor="hoeveel-mensen">Ik kom samen met:</label>

            <input
              name="hoeveel-mensen"
              id="hoeveel-mensen"
              type="text-area"
              rows="4"
              cols="5"
              placeholder="naam, naam"
            />
            <label htmlFor="music">
              Voeg je favoriete nummer toe aan onze afspeellijst
            </label>

            <input
              type="text"
              id="music"
              name="music"
              placeholder="artiest - nummer"
            />
            <div className="spotify-button">
              <label htmlFor="music">Of via Spotify </label>
              <ExternalLink
                target="_blank"
                href="https://open.spotify.com/playlist/1YpbxAhpcmoOpmgbnzDQEn?si=fT89T7gfQoSJUNvaVS_Mjw"
              >
                <SpotifyIcon />
              </ExternalLink>
            </div>
            <Button type="submit">Versturen</Button>
          </Form>
        </Grid>
      </Container>
    </Section>
  );
};

const Form = styled(animated.form)`
  text-align: left;
  background: ${props => props.theme.color.beach};
  padding: 5em;
  border-radius: 16px;
  display: grid;
  grid-gap: 1em;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);

  input {
    font-family: ${props => props.theme.font.secondary};
    border: none;
    line-height: 2em;

    ::placeholder {
      ${props => props.theme.font_size.small};
    }
  }

  .aanwezig-input input#aanwezig2 {
    margin-left: 1em;
  }

  .aanwezig-input {
    label {
      padding: 0.5em;
      font-size: 1.2em;
    }
  }

  .spotify-button {
    display: flex;
    align-items: center;

    svg {
      margin-left: 1em;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 3em;
    margin-bottom: 0;
  }
`;

const SpotifyIcon = styled(spotify)`
  height: auto;
  width: auto;
`;

const Button = styled.button`
  background: ${props => props.theme.color.coper};
  border: solid 1px #c7efcf 0.5;
  border-radius: 16px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  padding: 1em;
  ${props => props.theme.font_size.regular};
  font-weight: 700;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 1fr 1fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
